import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../app/store/store";
import { AccountMethodType } from "../../features/auth/account/form/controls/AccountMethod";
import { LogInFormData } from "../../features/auth/account/form/SignInForm";
import { SearchParamNames } from "../../features/auth/searchParamNames";
import { GeetestSuccessResponse } from "../../features/geetest/geetest";
import { GeetestData } from "../../features/geetest/GeetestData";
import { castle } from "../../lib/castle_io";
import { errorReturnStageSignIn } from "../../lib/returnStage";
import { readFromStorage, StorageKeys, writeToStorage } from "../../lib/storage";
import { authApi } from "../../services/authApi";
import { capture, SentryError } from "../../services/sentry";
import {
    BusinessErrorCode,
    CaptchaChallengeParameters,
    CaptchaChallengeResponse,
    CastleioError,
    CastleioErrorCode,
    ChallengeType,
    DeliveryType,
    HttpError,
    LoginMfaChallengeParameters,
    LoginParams,
    LoginRespondToChallengeRequest,
    MfaChallengeType,
    MfaParams,
    PasswordChallengeParameters,
    SetSocialMediaCodeArg,
    SetSocialMediaErrorArg,
    SignInError,
    SocialMediaAccountType,
    TwoFAError,
    TwoFAErrorCode,
} from "../../services/types";
import { appGetCountries, getSettings } from "../app/app";
import { COMPROMISED_ACCOUNT_ERROR_CODE } from "../constants";

export enum LogInStage {
    DATA = 0,
    TWO_FA = 1,
}

export type SignInState = {
    initCompleted: boolean;
    socialMediaCode?: string;
    socialMediaError?: string;
    socialMediaType?: SocialMediaAccountType;
    phone: string;
    email: string;
    accountMethod: AccountMethodType;
    stage: LogInStage;
    loginSessionId: string;
    mfaSessionId: string;
    twoFATitle: string;
    mfaChallengeType: MfaChallengeType;
    challengesArray: Array<ChallengeType>;
    captchaChallenge: GeetestData;
    password: string;
    isLoading: boolean;
    isCompromised: boolean;
    isModalLinkVisible: boolean;
    businessErrorCode?: string;
    isCaptchaError: boolean;
    isError: boolean;
    twoFAError?: TwoFAError;
    castleioError?: CastleioError;
    isResetAllowed: boolean;
    isTwoFAReset: boolean;
};

const initialState: SignInState = {
    initCompleted: false,
    socialMediaCode: "",
    socialMediaError: "",
    socialMediaType: undefined,
    phone: "",
    email: "",
    accountMethod: AccountMethodType.EMAIL,
    stage: LogInStage.DATA,
    loginSessionId: "",
    mfaSessionId: "",
    twoFATitle: "",
    mfaChallengeType: MfaChallengeType.phone,
    challengesArray: [],
    captchaChallenge: {} as GeetestData,
    password: "",
    isLoading: false,
    isCompromised: false,
    isModalLinkVisible: false,
    businessErrorCode: undefined,
    isCaptchaError: false,
    isError: false,
    twoFAError: undefined,
    castleioError: undefined,
    isResetAllowed: false,
    isTwoFAReset: false,
};

export type InitParams = {
    nextUrl: string;
    email: string;
    phone: string;
};

export type LoginUserResponse = {
    loginSessionId: string;
    mfaSessionId: string;
    challenges: Array<ChallengeType>;
    captchaChallenge: GeetestData;
    socialMediaError?: string;
    isResetAllowed?: boolean;
    email?: string;
    isModalLinkVisible?: boolean;
    initCompleted: boolean;
    stage?: LogInStage;
};

export type LoginUserRequest = {
    code?: string;
    type?: SocialMediaAccountType;
    socialMediaError?: string;
    loginSessionId?: string;
    mfaSessionId?: string;
};

export type RespondToChallengeResponse = {
    challenges: Array<ChallengeType>;
    captchaChallenge: GeetestData;
    mfaSessionId: string;
    isLoading: boolean;
    isResetAllowed?: boolean;
};

export type MfaResponse = {
    twoFATitle: string;
    mfaChallengeType: MfaChallengeType;
    stage?: LogInStage;
    mfaSessionId: string;
};

const BusinessErrorCodeMap: Record<SocialMediaAccountType, BusinessErrorCode> = {
    google: BusinessErrorCode.INVALID_GOOGLE_LOGIN,
    facebook: BusinessErrorCode.INVALID_FACEBOOK_LOGIN,
    apple: BusinessErrorCode.INVALID_APPLE_LOGIN,
};

export const init = createAsyncThunk(
    "signin/INIT",
    async ({ nextUrl, email, phone }: InitParams, { dispatch, getState }) => {
        if (email) {
            dispatch(setAccountMethod(AccountMethodType.EMAIL));
            dispatch(setEmail(email));
        } else if (phone) {
            dispatch(setAccountMethod(AccountMethodType.PHONE));
            dispatch(setPhone(phone));
        }

        await dispatch(appGetCountries());

        await dispatch(getSettings());

        const stateStore = getState() as RootState;
        const { config } = stateStore.app;

        let clientId = config.marketplaceClientId;
        let redirect = config.marketplaceUrl;
        const url = config.accountsAuthorizeUrl;
        let next;
        try {
            next = nextUrl && new URL(decodeURIComponent(nextUrl));
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
        if (next && next.origin + next.pathname === url) {
            clientId = next.searchParams.get(SearchParamNames.CLIENT_ID) || clientId;
            redirect = next.searchParams.get(SearchParamNames.REDIRECT_URI) || redirect;
            writeToStorage(StorageKeys.NEXT, next.href);
            writeToStorage(StorageKeys.STATE, next.searchParams.get(SearchParamNames.STATE));
            writeToStorage(
                StorageKeys.REFERRAL_CODE,
                next.searchParams.get(SearchParamNames.REFERRAL_CODE),
            );
        } else {
            const state = readFromStorage(StorageKeys.STATE);
            writeToStorage(
                StorageKeys.NEXT,
                `${url}?client_id=${clientId}&redirect_uri=${encodeURIComponent(
                    redirect,
                )}&state=${state}&response_type=code`,
            );
        }
        writeToStorage(StorageKeys.CLIENT_ID, clientId);
        writeToStorage(StorageKeys.REDIRECT_URL, redirect);
        writeToStorage(StorageKeys.ACCOUNTS_AUTHORIZE_URL, url);

        const { socialMediaError: error, socialMediaType, socialMediaCode } = stateStore.signIn;

        if (error) {
            const transactionName = `${socialMediaType}SignInError`;

            capture({
                error: new SentryError("OAuth 2.0 sign-in error", error),
                transactionName,
                fingerprint: ["react", error],
                tags: { ui: true, "ui-branch": "beta" },
                context: { info: { error } },
            });
            return dispatch(loginUser({ socialMediaError: error }));
        } else if (socialMediaCode) {
            return dispatch(loginUser({ code: socialMediaCode, type: socialMediaType }));
        }

        const { loginSessionId, mfaSessionId } = stateStore[signIn.name];

        return dispatch(loginUser({ loginSessionId, mfaSessionId }));
    },
);

export const loginUser = createAsyncThunk<
    LoginUserResponse,
    LoginUserRequest | undefined,
    { rejectValue: HttpError & { type: SocialMediaAccountType } }
>("signin/POST_LOGIN", async (args, { dispatch, rejectWithValue }) => {
    if (args?.loginSessionId) {
        return {
            loginSessionId: args?.loginSessionId,
            mfaSessionId: args?.mfaSessionId || "",
            challenges: [ChallengeType.password],
            captchaChallenge: {} as GeetestData,
            initCompleted: true,
            stage: args?.mfaSessionId ? LogInStage.TWO_FA : LogInStage.DATA,
        };
    }

    const code = args?.code;
    const type = args?.type;
    const socialMediaError = args?.socialMediaError;

    const loginParams: LoginParams = {
        ...(code && type && { type, code }),
        platform: "web",
        app_version: "0.0.1",
        client_id: readFromStorage(StorageKeys.CLIENT_ID),
    };
    const result = dispatch(authApi.endpoints.login.initiate(loginParams));
    return result
        .unwrap()
        .then(async (data) => {
            let geetestData: GeetestData = {} as GeetestData;
            let loginSessionId = "";
            let mfaSessionId = "";
            let isResetAllowed = false;
            let challenges: Array<ChallengeType> = [];
            let initCompleted = false;
            let email = "";
            let isModalLinkVisible = false;
            if (data.http_code === 200) {
                const loginToken = data?.auth_result?.login_token;
                const url = readFromStorage(StorageKeys.NEXT);
                window.location.href = `${url}&login_token=${loginToken}`;
            } else {
                const captchaChallenge = data?.challenges?.filter(
                    (challenge) => challenge?.challenge_name === ChallengeType.geetest,
                )[0];
                const mfaChallenge = data?.challenges?.filter(
                    (challenge) => challenge?.challenge_name === ChallengeType.mfa,
                )[0];
                const passwordChallenge = data?.challenges?.filter(
                    (challenge) => challenge?.challenge_name === ChallengeType.password,
                )[0];
                if (data?.login_session_id) {
                    loginSessionId = data.login_session_id;
                }
                if (data?.challenges) {
                    challenges = data.challenges?.map((challenge) => challenge.challenge_name);
                }
                if (captchaChallenge) {
                    const geetestParams =
                        captchaChallenge.challenge_parameters as CaptchaChallengeParameters;
                    geetestData = {
                        captchaId: geetestParams.captcha_id,
                        challenge: geetestParams.challenge,
                        newCaptcha: true,
                        offline: geetestParams.offline,
                        lang: "en",
                        hideClose: true,
                    };
                } else if (mfaChallenge) {
                    const mfaParams =
                        mfaChallenge.challenge_parameters as LoginMfaChallengeParameters;
                    mfaSessionId = mfaParams.mfa_session_id;
                    isResetAllowed = !!mfaParams?.reset_allowed;
                    await dispatch(
                        getMfaChallenge({
                            login_session_id: loginSessionId,
                            mfa_session_id: mfaSessionId,
                        }),
                    );
                } else if (passwordChallenge) {
                    const passwordParams =
                        passwordChallenge.challenge_parameters as PasswordChallengeParameters;
                    email = passwordParams?.email;
                    isModalLinkVisible = true;
                }
                initCompleted = true;
            }
            return {
                loginSessionId,
                mfaSessionId,
                challenges,
                captchaChallenge: geetestData,
                socialMediaError,
                isResetAllowed,
                email,
                isModalLinkVisible,
                initCompleted,
            };
        })
        .catch((error) =>
            rejectWithValue({
                ...error,
                type,
                code,
            }),
        )
        .finally(result.reset);
});

export const respondToChallenge = createAsyncThunk<
    RespondToChallengeResponse,
    LoginRespondToChallengeRequest,
    { rejectValue: { status: number; data: SignInError } }
>("signin/RESPOND_TO_CHALLENGE", async (args, { dispatch, rejectWithValue }) => {
    const result = dispatch(authApi.endpoints.respondToChallenge.initiate(args));

    return result
        .unwrap()
        .then(async (data) => {
            let geetestData: GeetestData = {} as GeetestData;
            let isLoading = false;
            let mfaSessionId = "";
            let isResetAllowed = false;
            if (data.http_code === 200) {
                const loginToken = data?.auth_result?.login_token;
                const url = readFromStorage(StorageKeys.NEXT);
                window.location.href = `${url}&login_token=${loginToken}`;
                isLoading = true;
            } else if (data?.challenges !== undefined) {
                const captchaChallenge = data?.challenges.filter(
                    (challenge) => challenge?.challenge_name === ChallengeType.geetest,
                )[0];
                const mfaChallenge = data?.challenges.filter(
                    (challenge) => challenge?.challenge_name === ChallengeType.mfa,
                )[0];
                if (captchaChallenge) {
                    const geetestParams =
                        captchaChallenge.challenge_parameters as CaptchaChallengeParameters;
                    geetestData = {
                        captchaId: geetestParams.captcha_id,
                        challenge: geetestParams.challenge,
                        newCaptcha: true,
                        offline: geetestParams.offline,
                        lang: "en",
                        hideClose: true,
                    };
                } else if (mfaChallenge) {
                    const mfaParams =
                        mfaChallenge.challenge_parameters as LoginMfaChallengeParameters;
                    mfaSessionId = mfaParams.mfa_session_id;
                    const loginSessionId = args.loginSessionId;
                    isResetAllowed = !!mfaParams?.reset_allowed;
                    await dispatch(
                        getMfaChallenge({
                            login_session_id: loginSessionId,
                            mfa_session_id: mfaSessionId,
                        }),
                    );
                }
                return {
                    challenges: data.challenges.map((challenge) => challenge.challenge_name),
                    captchaChallenge: geetestData,
                    mfaSessionId,
                    isLoading,
                    isResetAllowed,
                };
            }
            return {
                challenges: [],
                captchaChallenge: geetestData,
                mfaSessionId,
                isLoading,
            };
        })
        .catch((error) => rejectWithValue({ status: error.status, data: error.data }))
        .finally(result.reset);
});

export const getMfaChallenge = createAsyncThunk<MfaResponse, MfaParams>(
    "signin/GET_MFA_CHALLENGE",
    async (args, { dispatch, rejectWithValue }) => {
        const result = dispatch(authApi.endpoints.getMfaLogin.initiate(args));
        return result
            .unwrap()
            .then(async (data) => {
                let mfaChallengeType = MfaChallengeType.phone;
                let twoFATitle = "";
                let stage;
                if (data?.challenges !== undefined) {
                    mfaChallengeType = data.challenges[0]?.challenge_name;
                    const parameters = data.challenges[0]?.challenge_parameters;
                    stage = LogInStage.TWO_FA;
                    if (
                        parameters &&
                        [MfaChallengeType.email, MfaChallengeType.phone].includes(mfaChallengeType)
                    ) {
                        twoFATitle =
                            (parameters.email ? parameters.email : parameters.phone_number) || "";
                    }
                }
                return {
                    twoFATitle,
                    mfaChallengeType,
                    stage,
                    mfaSessionId: args.mfa_session_id,
                };
            })
            .catch((error) => rejectWithValue(error.data))
            .finally(result.reset);
    },
);

export const respondToMFAChallenge = createAsyncThunk<void, string, { rejectValue: TwoFAError }>(
    "signin/RESPOND_TO_MFA_CHALLENGE",
    async (code, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const { loginSessionId, mfaSessionId, mfaChallengeType } = state[signIn.name];

        const result = dispatch(
            authApi.endpoints.respondToMfaChallenge.initiate({
                login_session_id: loginSessionId,
                mfa_session_id: mfaSessionId,
                challenge_name: mfaChallengeType,
                challenge_response: { code },
            }),
        );

        return result
            .unwrap()
            .then(async () => {
                const challengeData: LoginRespondToChallengeRequest = {
                    loginSessionId,
                    challenges: [{ challenge_name: ChallengeType.mfa }],
                };
                dispatch(respondToChallenge(challengeData));
            })
            .catch((error) => rejectWithValue(error.data))
            .finally(result.reset);
    },
);

export const resendMfaCode = createAsyncThunk<void, void, { rejectValue: TwoFAError }>(
    "signin/RESEND_MFA_CODE",
    async (args, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const { loginSessionId, mfaSessionId, mfaChallengeType } = state[signIn.name];

        const deliveryType =
            mfaChallengeType === MfaChallengeType.email ? DeliveryType.email : DeliveryType.sms;

        const result = dispatch(
            authApi.endpoints.resendMfaCode.initiate({
                delivery_type: deliveryType,
                login_session_id: loginSessionId,
                mfa_session_id: mfaSessionId,
            }),
        );

        return result
            .unwrap()
            .then(async () => undefined)
            .catch((error) => rejectWithValue(error.data))
            .finally(result.reset);
    },
);

export const resetTwoFA = createAsyncThunk<void, void, { rejectValue: TwoFAError }>(
    "signin/RESET_2FA",
    async (args, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const { loginSessionId } = state[signIn.name];

        const result = dispatch(
            authApi.endpoints.reset2fa.initiate({
                loginSessionId,
            }),
        );

        return result
            .unwrap()
            .catch((error) => rejectWithValue(error.data))
            .finally(result.reset);
    },
);

export const enrichWithCastleIOChallenge = async (
    challengesArray: Array<ChallengeType>,
    challengeData: LoginRespondToChallengeRequest,
) => {
    if (challengesArray.includes(ChallengeType.castleio)) {
        const castleIORequestToken = await castle.createRequestToken();
        challengeData.challenges.push({
            challenge_name: ChallengeType.castleio,
            challenge_response: {
                castleio_request_token: castleIORequestToken,
            },
        });
    }
};

export const respondToChallengeWithPassword = createAsyncThunk(
    "signin/RESPOND_TO_CHALLENGE_PASSWORD",
    async (args: LogInFormData & { loginSessionId?: string }, { dispatch, getState }) => {
        const state = getState() as RootState;
        const { loginSessionId, challengesArray } = state[signIn.name];

        const challengeData: LoginRespondToChallengeRequest = {
            loginSessionId,
            challenges: [
                {
                    challenge_name: challengesArray.includes(ChallengeType.password)
                        ? ChallengeType.password
                        : ChallengeType.username_password,
                    challenge_response: {
                        ...(args.phone && { phone: args.phone }),
                        ...(args.email && { email: args.email }),
                        password: args.password,
                    },
                },
            ],
        };

        await enrichWithCastleIOChallenge(challengesArray, challengeData);

        return dispatch(respondToChallenge(challengeData));
    },
);

export const respondToChallengeWithCaptcha = createAsyncThunk(
    "signin/RESPOND_TO_CHALLENGE_CAPTCHA",
    async (args: GeetestSuccessResponse & LogInFormData, { dispatch, getState }) => {
        const state = getState() as RootState;
        const { loginSessionId, captchaChallenge, challengesArray } = state[signIn.name];
        const challengeResponse: CaptchaChallengeResponse = {
            challenge: captchaChallenge.challenge,
            seccode: args.geetest_seccode,
            validate: args.geetest_validate,
        };

        const challengeData: LoginRespondToChallengeRequest = {
            loginSessionId,
            challenges: [
                {
                    challenge_name: ChallengeType.username_password,
                    challenge_response: {
                        ...(args.phone && { phone: args.phone }),
                        ...(args.email && { email: args.email }),
                        password: args.password,
                    },
                },
                {
                    challenge_name: ChallengeType.geetest,
                    challenge_response: challengeResponse,
                },
            ],
        };

        await enrichWithCastleIOChallenge(challengesArray, challengeData);

        return dispatch(respondToChallenge(challengeData));
    },
);

export const signIn = createSlice({
    name: "signIn",
    initialState,
    reducers: {
        setInitCompleted(draft, { payload }: PayloadAction<boolean>) {
            draft.initCompleted = payload;
        },
        setLoading(draft, { payload }: PayloadAction<boolean>) {
            draft.isLoading = payload;
        },
        setLoginSessionId(draft, { payload }: PayloadAction<string>) {
            draft.loginSessionId = payload;
        },
        setSocialMediaCode(draft, { payload }: PayloadAction<SetSocialMediaCodeArg>) {
            draft.socialMediaCode = payload.code;
            draft.socialMediaType = payload.type;
        },
        setSocialMediaError(draft, { payload }: PayloadAction<SetSocialMediaErrorArg>) {
            draft.socialMediaError = payload.error;
            draft.socialMediaType = payload.type;
        },
        setPhone(draft, { payload }: PayloadAction<string>) {
            draft.phone = payload;
        },
        setEmail(draft, { payload }: PayloadAction<string>) {
            draft.email = payload;
        },
        setAccountMethod(draft, { payload }: PayloadAction<AccountMethodType>) {
            draft.accountMethod = payload;
            draft.phone = "";
            draft.email = "";
            draft.isCaptchaError = false;
        },
        setModalLinkVisible(draft, { payload }: PayloadAction<boolean>) {
            draft.isModalLinkVisible = payload;
        },
        setStage(draft, { payload }: PayloadAction<LogInStage>) {
            draft.stage = payload;
        },
        setPassword(draft, { payload }: PayloadAction<string>) {
            draft.password = payload;
        },
        resetCaptcha(draft) {
            draft.captchaChallenge = {} as GeetestData;
            draft.challengesArray = [];
        },
        resetChallengesArray(draft) {
            draft.challengesArray = [];
        },
        setCaptchaError(draft, { payload }: PayloadAction<boolean>) {
            draft.isCaptchaError = payload;
        },
        setBusinessErrorCode(draft, { payload }: PayloadAction<BusinessErrorCode | undefined>) {
            draft.businessErrorCode = payload && payload.valueOf();
        },
        resetAllErrors(draft) {
            draft.businessErrorCode = undefined;
            draft.isCaptchaError = false;
            draft.twoFAError = undefined;
            draft.castleioError = undefined;
        },
        reset(draft) {
            const { initCompleted } = draft;
            return { ...initialState, initCompleted };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(appGetCountries.rejected, (draft) => {
            draft.initCompleted = true;
            draft.isError = true;
        });

        builder.addCase(getSettings.rejected, (draft) => {
            draft.initCompleted = true;
            draft.isError = true;
        });

        builder.addCase(loginUser.pending, (draft) => {
            draft.businessErrorCode = undefined;
            draft.isCaptchaError = false;
        });

        builder.addCase(loginUser.fulfilled, (draft, { payload }) => {
            draft.loginSessionId = payload.loginSessionId;
            draft.mfaSessionId = payload.mfaSessionId;
            draft.challengesArray = payload.challenges;
            draft.captchaChallenge = payload.captchaChallenge;
            if (payload.socialMediaError) {
                draft.businessErrorCode = BusinessErrorCode.SOCIAL_MEDIA_ERROR;
            }
            if (payload.isResetAllowed) {
                draft.isResetAllowed = payload.isResetAllowed;
            }
            if (payload.email) {
                draft.email = payload.email;
            }
            if (payload.isModalLinkVisible) {
                draft.isModalLinkVisible = payload.isModalLinkVisible;
            }
            if (payload.stage) {
                draft.stage = payload.stage;
            }
            if (payload.initCompleted) {
                draft.initCompleted = payload.initCompleted;
            }
        });

        builder.addCase(loginUser.rejected, (draft, { payload }) => {
            draft.initCompleted = true;
            if (payload) {
                const { status, data, type } = payload;
                if ([403, 422].includes(status)) {
                    const code = data.code;
                    if (code === COMPROMISED_ACCOUNT_ERROR_CODE) {
                        draft.isCompromised = true;
                    } else {
                        draft.businessErrorCode = code;
                    }
                } else if (status === 401) {
                    if (type) {
                        draft.businessErrorCode = BusinessErrorCodeMap[type];
                    }
                } else {
                    draft.isError = true;
                }
            } else {
                draft.isError = true;
            }
        });

        builder.addCase(respondToChallenge.rejected, (draft, { payload }) => {
            if (payload) {
                const { status, data } = payload;
                if ([403, 422].includes(status)) {
                    const code = data.code;
                    if (code === COMPROMISED_ACCOUNT_ERROR_CODE) {
                        draft.isCompromised = true;
                    } else if (code === CastleioErrorCode.SUSPICIOUS_ACTIVITY) {
                        draft.castleioError = data as CastleioError;
                    } else {
                        draft.businessErrorCode = code;
                    }
                } else if (status === 401) {
                    draft.businessErrorCode = BusinessErrorCode.INVALID_LOGIN_PASSWORD;
                } else {
                    draft.isError = true;
                }
            }
            draft.stage = errorReturnStageSignIn(draft.businessErrorCode);
            draft.challengesArray = [];
            draft.isLoading = false;
        });

        builder.addCase(respondToChallenge.pending, (draft) => {
            draft.isLoading = true;
        });

        builder.addCase(respondToChallenge.fulfilled, (draft, { payload }) => {
            draft.challengesArray = payload.challenges;
            draft.captchaChallenge = payload.captchaChallenge;
            draft.mfaSessionId = payload.mfaSessionId;
            draft.isLoading = payload.isLoading;
            if (payload.isResetAllowed) {
                draft.isResetAllowed = payload.isResetAllowed;
            }
        });

        builder.addCase(respondToChallengeWithCaptcha.fulfilled, (draft) => {
            draft.captchaChallenge = {} as GeetestData;
        });

        builder.addCase(getMfaChallenge.rejected, (draft) => {
            draft.isError = true;
        });

        builder.addCase(getMfaChallenge.fulfilled, (draft, { payload }) => {
            draft.twoFATitle = payload.twoFATitle;
            draft.mfaChallengeType = payload.mfaChallengeType;
            if (payload.stage) {
                draft.stage = payload.stage;
            }
            draft.mfaSessionId = payload.mfaSessionId;
        });

        builder.addCase(respondToMFAChallenge.rejected, (draft, { payload }) => {
            if (
                payload?.code === TwoFAErrorCode.WRONG_CODE &&
                payload?.remaining_attempts_count === 0
            ) {
                draft.twoFAError = undefined;
                draft.businessErrorCode = BusinessErrorCode.TOO_MANY_CHALLENGE_FAILURES;
                draft.stage = errorReturnStageSignIn(draft.businessErrorCode);
            } else {
                draft.twoFAError = payload;
                draft.stage = errorReturnStageSignIn(draft.twoFAError?.code);
            }
            draft.challengesArray = [];
            draft.isLoading = false;
        });

        builder.addCase(respondToMFAChallenge.pending, (draft) => {
            draft.isLoading = true;
        });

        builder.addCase(resendMfaCode.rejected, (draft, { payload }) => {
            draft.twoFAError = payload;
            draft.isLoading = false;
        });

        builder.addCase(resendMfaCode.pending, (draft) => {
            draft.isLoading = true;
            draft.twoFAError = undefined;
        });

        builder.addCase(resendMfaCode.fulfilled, (draft) => {
            draft.isLoading = false;
        });

        builder.addCase(resetTwoFA.pending, (draft) => {
            draft.isLoading = true;
            draft.isTwoFAReset = false;
        });

        builder.addCase(resetTwoFA.fulfilled, (draft) => {
            draft.isLoading = false;
            draft.isTwoFAReset = true;
            draft.twoFAError = undefined;
        });

        builder.addCase(resetTwoFA.rejected, (draft, { payload }) => {
            draft.isLoading = false;
            draft.twoFAError = payload;
        });
    },
});

export const {
    setInitCompleted,
    setLoading,
    setLoginSessionId,
    setSocialMediaCode,
    setSocialMediaError,
    setPhone,
    setEmail,
    setAccountMethod,
    setModalLinkVisible,
    setStage,
    setPassword,
    resetCaptcha,
    resetChallengesArray,
    setCaptchaError,
    setBusinessErrorCode,
    resetAllErrors,
    reset,
} = signIn.actions;
